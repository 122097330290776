<template>
  <v-container class="mt-5 pb-5 mb-5">
    <loading-flux :value="loading"></loading-flux>
    <v-row class="mt-5">
      <v-col cols="12" md="12" sm="12">
        <section class="d-flex flex-column flex-sm-row justify-content-between">
          <h2 class="ml-1">
            Correo de confirmación de ingreso de formularios
          </h2>
          <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
        </section>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-tabs vertical>
          <v-tab class="mt-1">
            <v-icon left>
              mdi-view-list
            </v-icon>
            <small class="mx-2">
              Formulario de productos
            </small>
          </v-tab>
          <v-tab class="mt-1">
            <v-icon left>
              mdi-account-box
            </v-icon>
            <small class="mx-2">
              Formulario de proveedores
            </small>
          </v-tab>
          <v-tab-item>
            <v-card flat class="">
              <v-card-text>
                <section class="mx-5">
                  <v-simple-table class="elevation-3 w-75">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Correo electrónico
                          </th>
                          <th class="text-left">
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in mailsMaterials" :key="item.id">
                          <td>
                            <p class="text-left">{{ item.email }}</p>
                          </td>
                          <td>
                            <div class="d-flex flex-column">
                              <v-btn
                                icon
                                @click="deleteEmail(item, formTypes.material)"
                                color="red"
                                class="mr-2"
                              >
                                <v-icon small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-form
                    class="mt-16"
                    @submit.prevent="updateEmailForm(formTypes.material)"
                  >
                    <v-row align="center">
                      <v-col cols="12" md="12" sm="12" class="pb-0">
                        <v-text-field
                          outlined
                          v-model="$v.form.email_product.$model"
                          :error-messages="
                            $v.form.email_product.$invalid
                              ? 'Debe ser un email válido'
                              : null
                          "
                          label="Correo electrónico para notificación de nuevos productos"
                          clearable
                          type="email"
                          class="w-75"
                        ></v-text-field>
                      </v-col>
                      <v-col class="" cols="12" md="12" sm="12">
                        <section class="w-75">
                          <v-btn
                            @click="updateEmailForm(formTypes.material)"
                            :disabled="
                              !form.email_product ||
                                $v.form.email_product.$invalid
                            "
                            color="blue darken-3 text-white"
                            class="float-right"
                          >
                            Agregar Correo
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
                  </v-form>
                </section>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat class="">
              <v-card-text>
                <section class="mx-5">
                  <v-simple-table class="elevation-3 w-75">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Correo electrónico
                          </th>
                          <th class="text-left">
                            Acción
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in mailsSuppliers" :key="item.id">
                          <td>
                            <p class="text-left">{{ item.email }}</p>
                          </td>
                          <td>
                            <div class="d-flex flex-column">
                              <v-btn
                                icon
                                @click="deleteEmail(item, formTypes.supplier)"
                                color="red"
                                class="mr-2"
                              >
                                <v-icon small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-form
                    class="mt-16"
                    @submit.prevent="updateEmailForm(formTypes.supplier)"
                  >
                    <v-row align="center">
                      <v-col cols="12" md="12" sm="12" class="pb-0">
                        <v-text-field
                          outlined
                          v-model="$v.form.email_supplier.$model"
                          :error-messages="
                            $v.form.email_supplier.$invalid
                              ? 'Debe ser un email válido'
                              : null
                          "
                          label="Correo electrónico para notificación de nuevos proveedores"
                          clearable
                          type="email"
                          class="w-75"
                        ></v-text-field>
                      </v-col>
                      <v-col class="" cols="12" md="12" sm="12">
                        <section class="w-75">
                          <v-btn
                            @click="updateEmailForm(formTypes.supplier)"
                            :disabled="
                              !form.email_supplier ||
                                $v.form.email_supplier.$invalid
                            "
                            color="blue darken-3 text-white"
                            class="float-right"
                          >
                            Agregar Correo
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
                  </v-form>
                </section>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import appConfig from "@/app.config";
import { mapActions } from "vuex";
import swal2 from "sweetalert2";
import { authUsuarioComputed } from "@/state/helpers";
import { email } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Correo de confirmación de ingreso de formularios",
    meta: [{ name: "description", content: appConfig.description }],
  },
  async mounted() {
    await this.getEmailsNotificationFormsData();
  },
  validations: {
    form: {
      email_product: {
        email,
      },
      email_supplier: {
        email,
      },
    },
  },
  data() {
    return {
      loading: false,
      formTypes: {
        material: "Material",
        supplier: "Proveedor",
      },
      mailsSuppliers: [],
      mailsMaterials: [],
      form: {
        email_product: null,
        email_supplier: null,
      },
      breadcrumb: [
        {
          text: "Inicio",
          href: "/",
          disabled: false,
        },
        {
          text: "Correo de confirmación de ingreso de formularios",
          disabled: true,
          href: "/mantenedores/correo/ingreso/formularios",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getEmailsNotificationForms: "maintainers/getEmailsNotificationForms",
      updateEmailNotificationForm: "maintainers/updateEmailNotificationForm",
      deleteEmailNotificationForm: "maintainers/deleteEmailNotificationForm",
    }),
    async getEmailsNotificationFormsData() {
      const response = await this.getEmailsNotificationForms();
      this.mailsMaterials = response.items.filter(
        (item) => item.mail_type == this.formTypes.material
      );
      this.mailsSuppliers = response.items.filter(
        (item) => item.mail_type == this.formTypes.supplier
      );
    },
    async updateEmailForm(email_type) {
      this.loading = true;
      const resp = await this.updateEmailNotificationForm({
        user: this.user.email,
        email:
          email_type == this.formTypes.material
            ? this.form.email_product
            : this.form.email_supplier,
        email_type,
      });
      if (resp.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: `Correo de notificación de ${
            email_type == this.formTypes.material ? "Productos" : "Proveedores"
          } agregado correctamente`,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
        this.form.email_product = null;
        this.form.email_supplier = null;
        await this.getEmailsNotificationFormsData();
      } else {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    async deleteEmail({ id, email }, email_type) {
      this.$swal
        .fire({
          title: `¿Estás seguro que quieres eliminar este correo: <b>${email}</b>?`,
          text: "Esta acción no se puede deshacer",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.value) {
            this.loading = true;
            const resp = await this.deleteEmailNotificationForm({
              mail_id: id,
            });
            if (resp.status == 200) {
              swal2.fire({
                icon: "success",
                title: "Excelente",
                text: "Correo eliminado correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              if (email_type == this.formTypes.material) {
                this.mailsMaterials = this.mailsMaterials.filter(
                  (item) => item.id != id
                );
              } else {
                this.mailsSuppliers = this.mailsSuppliers.filter(
                  (item) => item.id != id
                );
              }
            } else {
              swal2.fire({
                icon: "error",
                title: "Error",
                text: "Ha ocurrido un error, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.loading = false;
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>
